<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        眼科药物一站式服务平台
      </div>
      <div class="topic_desc_en">
        ONE-STOP SERVICE PLATFORM FOR OPHTHALMIC DRUGS
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc">
        {{thematicDesc}}
      </div>
      <div class="section_content">
        <div class="cards_panel">
          <div class="card_item" v-for="(cItem,cIdx) in cardInfos" :key="cIdx">
            <div class="card_title">
              {{cItem.label}}
            </div>
            <div class="card_desc">
              <div class="item_line" v-for="(dItem,dIdx) in cItem.details" :key="dIdx">
                <div class="item_line_icon"></div>
                <div class="item_line_value">
                  {{dItem}}
                </div>
              </div>
              <div v-if="cItem.desc">
                {{cItem.desc}}
              </div>
            </div>
          </div>
        </div>
        <div class="pic_panel">
          <img :src="modulePic">
        </div>
      </div>
      <div class="section_content">
        <div class="section_title section_title_feature">临床研究</div>
        <div class="item_line" v-for="(iItem,iIdx) in points" :key="iIdx">
          <div class="item_line_icon"></div>
          <div class="item_line_value">
            {{iItem}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "modulePic":"",
      "thematicDesc":".",
      "cardInfos":[],
      "points":[]
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      let lang = this.$i18n.locale;
      return this.infoData[lang] || "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeFeatures&secondaryTitle=one_stopServicePlatformForOphthalmicDrugs`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.modulePic = `${window.PICSEVERURL}/${configData.modulePic}`;
          _this.thematicDesc = configData.thematicDesc;
          _this.cardInfos = configData.cardInfos;
          _this.points = configData.points;
        }else{
          _this.$notify({
            title: '失败',
            message: "眼科药物一站式服务平台数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">

.item_line{
  display: flex;
  align-items: flex-start;
  .item_line_icon{
    width: 15px;
    height: 15px;
    background: url("./images/icon@checked.png") 0 0 no-repeat;
    background-size: 100% auto;
    background-position: center left;
    margin: 10px 7px 10px 0;
  }
  .item_line_value{
    flex: 1;
    overflow: hidden;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 35px;
  }
}
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 1.5rem;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.4rem;
      margin-bottom: 2rem;
    }
    .section_content{
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #000000;
        height: 2rem;
        line-height: 2rem;
        padding-left: 3rem;
        box-sizing: border-box;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
      }
      .cards_panel{
        margin-bottom: 1rem;
        .card_item{
          flex: 1;
          overflow: hidden;
          padding: 1rem 0.5rem;
          box-sizing: border-box;
          background: #F6F7F9;
          .card_title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.2rem;
            color: #0086D1;
            line-height: 1.4rem;
            margin-bottom: 0.5rem;
          }
          .card_desc{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 1rem;
            color: #333333;
            line-height: 1.4rem;
          }
        }
      }
      .pic_panel{
        img{
          width: 100%;
          margin-bottom: 2rem;
        }
      }
      .list_panel{
        background: #32a4de;
        padding: 1rem 0.5rem;
        box-sizing: border-box;
        margin-bottom: 1.5rem;
        .list_panel_topic{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 1.4rem;
          color: #FFFFFF;
          line-height: 1.6rem;
          margin-bottom: 1.5rem;
        }
        .list_content{
          .list_item{
            flex: 1;
            overflow: hidden;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.1);
            border-radius: 0.5rem;
            padding: 1rem 0.5rem;
            .list_item_title{
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 1.2rem;
              color: #0086D1;
              line-height: 1.4rem;
            }
            .list_line{
              display: flex;
              align-items: flex-start;
              margin-bottom: 0.5rem;
              .list_line_icon{
                width: 1rem;
                height: 1rem;
                background: url("./images/icon@checked.png") 0 0 no-repeat;
                background-size: 100% auto;
                background-position: center left;
                // margin: 0.5rem 7px 0.5rem 0;
                margin-right: 0.5rem;
              }
              .list_line_value{
                flex: 1;
                overflow: hidden;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 1rem;
                color: #333333;
                line-height: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>